import React from 'react';
import {TwoColumn, ResponsiveContainer, FontScale} from '@components/layout';
import {ThemeProvider, Colors, styled} from '@styles';
import {StyledSection} from '@components/sections';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';

const Container = styled(StyledSection).withConfig({
  componentId: 'legalPageContainer'
})`
  max-width: 100%;

  .wide & {
    max-width: 80%;
  }
`;

const LegalPage = (props: RichTextProps): JSX.Element => {
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.White,
        minPadding: '6em 2em',
        maxPadding: '10em 0 4em',
      }}
    >
      <ResponsiveContainer as={FontScale}>
        <Container>
          <TwoColumn>
            <RichTextCallout {...props} />
          </TwoColumn>
        </Container>
      </ResponsiveContainer>
    </ThemeProvider>
  );
};

export default LegalPage;
