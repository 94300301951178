import {styled} from '@styles';
import ResponsiveContainer from './ResponsiveContainer';

const TwoColumn = styled(ResponsiveContainer).withConfig({
  componentId: 'twoColumnColumn'
})`
  display: grid;
  grid-template-columns: 1fr;

  &.wide {
    grid-template-columns: 1fr 1fr;
  }
`;

export default TwoColumn;
